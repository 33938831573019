.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

/*
.header {
  background-color: #7987a3;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

body {
  /*background-color: #c7c7c7;*/
  /*background-color: rgba(45, 129, 168, 0.486);*/
  background-color: rgb(235, 227, 215);
  margin: 0;
  font-family: "Lucida Console", Monaco, monospace
    /*font-family:  Monaco, "Arial Narrow", Helvetica, sans-serif;*/
}

/* Home Profile */

.bio {
  overflow: show;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28vh;
  margin-bottom: 5vh;
}

.portrait {
  width: 35vmin;
  border-radius: 100%;
  /*border: 6px solid rgb(176, 154, 206);*/
  /*border: 6px solid rgb(64, 117, 139);*/
  float: left;
  margin-bottom: inherit;
  vertical-align: middle;
}

.intro {
  float: left;
  margin-left: 3vh;

  max-width: 35vmin;
  height: 35vmin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  margin: 1.9vmin auto;
  display: inline-block;
  line-height: 10px;
  font-size: 3.5vmin;
}

.description {
  margin: 0px auto;
  display: inline-block;
  font-size: 2.3vmin;
}

.degree_title {
  margin: 0px auto;
  display: inline-block;
  font-size: 2vmin;
}

.temp_paragraph {
  margin-top: 50vh;
  padding: 30px 15px 2500px;
  font-size: 30px;
}

/* Navigation */

.header {
  display: inline;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  color: #f2f2f2;
  float: left;
  margin-top: 9px;
  margin-left: 4vmin;
  margin-right: 1vmin;
  text-align: center;
  width: 35px;
  border-radius: 100%;
}

.title {
  color: #f2f2f2;
  float: left;
  padding: 12px 1vmin;
  text-align: center;
  font-size: 20px;
}

.text {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.menubar {
  /*background-color: #333;*/
  background-color: rgb(73, 73, 73);
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 10;
  box-shadow: 0 .5px 13px -5px;
}

.menubar a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.menubar-right {
  float: right;
  display: flex;
}

.menubar a:hover {
  /*background-color: #ddd;*/
  /*text-decoration: underline;*/
  border-bottom: 3px solid currentColor;
  text-decoration: none;
  color: white;
  /*color: black;*/
}

.menubar a.active {
  /*background-color: #e27a19;
  border-bottom: 3px solid #e27a19;*/
  background-color: rgb(64, 117, 139);
  border-bottom: 3px solid rgb(64, 117, 139);
  color: white;
}

.menubar a.active:hover {
  border-bottom: 3px solid currentColor;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: none;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown:hover .dropbtn {
  border-bottom: 3px solid currentColor;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-bottom: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Social */

.social_footer {
  position: fixed;
  vertical-align: middle;
  float: none;
  width: 50%;
  height: 200px;
  top: 102%;
  left: 50%;
  margin-top: -100px;
  /* Negative half of height. */
  margin-left: -25%;
  /* Negative half of width. */
  text-align: center;
}

.social {
  color: rgb(73, 73, 73);
  font-size: 3.5vmin
}

.social:hover {
  opacity: 0.6;
  transition-duration: 600ms;
}

/* Photo Gallery */

.slideshow-container {
  width: 63vw !important;
  padding-top: 8vh;
  margin: auto;
  overflow: show;
}

.photo-slide {}

.horizontal {
  width: 63vw !important;
}

.vertical {
  width: 28vw !important;
}

.img-desc {
  line-height: 20px;
  text-align: center;
}

/* Resume */

.resume {
  display: block;
  width: 60.5vmin;
  height: 78vmin;
  margin: auto;
  padding-top: 7vh;
  border: none;
}

/* About */

.about-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 4vh 20vw
}

.divider {
  border-bottom: 1px solid rgb(64, 117, 139);
  padding-top: 2vh;
  margin-bottom: 2vh;
}

.nav-header {
  padding-bottom: 10vh;
}

.about-me {
  overflow: show;
  padding: 0px 0px 25px;
  font-size: 20px;
  line-height: 25px;
  /*border-bottom: 1px solid rgb(64, 117, 139);*/
}

.me {
  display: block;
  padding: 50px 15px 0px;
}

.desc {
  text-align: left;
  float: left;
  padding-left: 1vw;
  padding-top: 0vh;
  max-width: 45vw;
  box-sizing: border-box;
}

.icon {
  width: 10vw;
  float: left;
  vertical-align: middle;

  margin-left: 2vw;
  margin-right: 2vw;
  border-radius: 10%;
}

.button {
  /*border: 1px solid rgb(73, 73, 73);*/
  color: white;
  background-color: rgb(73, 73, 73);
  padding: 7px 7px;
  text-decoration: none;
  font-size: 19px;
}

.button:hover {
  background-color: rgb(64, 117, 139);
}

.demo-spacing {
  text-align: left;

}

.demo-button {
  float: right;
}

.descinfo {
  font-size: .8em;
  line-height: 20px;
}

.about-table {
  text-align: left;
}

.table-desc {
  padding-left: 1vw;

}

.hobbies {
  display: flex;
  flex-direction: row;

}

.hobby {
  width: 30vw;
  padding: 0px 20px
}

.hobby-icon {
  width: 4vw;
  vertical-align: middle;
}


.end {
  padding-bottom: 10vh;
}

/* Mobile Display */

@media screen and (max-width: 710px) {

  /* Navigation */
  .menubar {
    padding: 10px 0px 0px !important;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menubar a {
    text-align: left;
    font-size: 3vw;
  }

  .menubar-right {
    float: right;

  }

  .dropdown .dropbtn {
    font-size: 3vw !important;
  }

  .header {
    float: none;
    display: inline;
  }

  .title {
    float: none;
    text-align: center;
    vertical-align: middle;
    font-size: 4vw;
    padding: 12px 0vmin !important;
    margin: 0 0;
  }

  .logo {
    float: none;
    vertical-align: middle;
    padding: 12px 4vmin !important;
    margin: 0 0;
  }

  /* Home Page */
  .bio {
    overflow: show;
    margin-left: auto;
    margin-right: auto;
    margin-top: 31vh;
    margin-bottom: 5vh;
  }

  .portrait {
    margin-left: inherit;
    margin-right: inherit;
    float: left;
    margin-bottom: inherit;
    vertical-align: middle;
    width: 35vmin;
  }

  .intro {
    float: left;
    margin-left: 2vh;
  }

  .name {
    margin: 10px auto;
    font-size: 3.5vmin;
  }

  .paragraph {
    margin: 10px auto;
    display: inline-block;
    font-size: 3vmin;
  }

  .temp_paragraph {
    margin-top: 50vh;
    padding: 100px 15px 2500px;
    font-size: 30px;
  }

  .social_footer {
    top: 105%;
  }

  .social {
    color: rgb(73, 73, 73);
    font-size: 4vh
  }

  /* Photo Gallery */

  .slideshow-container {
    padding-top: 20vh !important;
    width: 62vw !important;
  }

  .img-desc {
    line-height: 15px;
    text-align: center;
    font-size: .8em;
    width: 60vw;
  }

  /* About */

  .about-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10vh 10vw
  }

  .about-me {
    overflow: show;
    padding: 0px 0px 25px;
    font-size: 20px;
    line-height: 25px;
  }

  .me {
    display: block;
    padding: 50px 15px 0px;
  }

  .desc {
    text-align: left;
    float: left;
    padding-left: 1vw;
    max-width: 60vw;
    box-sizing: border-box;
  }

  .icon {
    width: 10vw;
    float: left;
    vertical-align: middle;

    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 10%;
  }

  .button {
    /*border: 1px solid rgb(73, 73, 73);*/
    color: white;
    background-color: rgb(73, 73, 73);
    padding: 7px 7px;
    text-decoration: none;
    font-size: 19px;
  }

  .button:hover {
    background-color: rgb(64, 117, 139);
  }

  .demo-spacing {
    text-align: left;
  }

  .demo-button {
    float: center;
    display: inline-block;
  }

  .desctime {
    font-size: 17px;
  }

  .descinfo {
    font-size: .8em;
    line-height: 20px;
  }

  .about-table {
    text-align: left;
  }

  .table-desc {
    padding-left: 1vw;

  }

  .hobbies {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;

  }

  .hobby {
    width: 60vw;
    padding: 5px 20px
  }

  .hobby-icon {
    width: 10vw;
    vertical-align: middle;
  }

  .resume {
    padding-top: 20vh !important;
    width: 74vmin !important;
    height: 95vmin !important;
  }
}